<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-12 help-info">
        <h2>Что такое BETEXY и для кого он сделан?</h2>
        <div class="article">
          <b>BETEXY</b> - это уникальный сервис для заработка на ставках на спорт. <br>Наши
          инструменты автоматизации позволят вам в несколько кликов настроить собственную экосистему ставок и получать
          пассивный доход почти ничего не делая! <br>Стоимость недельной подписки составляет 20€ за одного бота.
        </div>
        <h2>Как начать работу?</h2>
        <div class="article">
          <ul>
            <li>
              Регистрируем аккаунт на сайте букмекера и пополняем там баланс.
            </li>
            <li>
              Пополняем баланс в Betexy, нажав на иконку баланса в меню -
              <b-icon size="is-small" icon="wallet"/>
              0.00 €. (Внимание! Средства с баланса Betexy не возвращаются!)
            </li>
            <li>
              Создаем аккаунт в меню Аккаунты. Выбираем БК, заполняем валюту, логин и пароль, на вкладке Стратегии выбираем стратегию соответствующую нашей БК, вписываем только одну сумму ставки. Сумму ставки обязательно указываем именно ту, которая будет ставится в БК, в валюте аккаунта. Рекомендуемая сумма ставки 1/25 от депозита. Сохраняем аккаунт.
            </li>
            <li>
              Нажимаем на иконку с корзиной, покупаем подписку на неделю. Срок окончания подписки можно увидеть в меню Аккаунты.
            </li>
            <li>
              Нажимаем на иконку <b-icon icon="play" size="is-small"></b-icon>, скачивается файл бота в виде архива .zip.
            </li>
            <li>
              Распаковываем архив, потом в любом браузере/антидетекте (кроме Mozilla) заходим в меню Расширение, включаем Режим разработчика в правом верхнем углу, нажимаем Загрузить распакованное расширение.
            </li>
            <li>
              Бот откроет страницу сайта БК и залогинится. Все, бот запущен и готов к работе.
            </li>
          </ul>
        </div>
        <h2>Рекомендации по установке бота</h2>
        <div class="article">
          <ul>
            <li>
              Каждый бот должен стоять в отдельном профиле браузера/антидетекта. Нельзя устанавливать 2 или более расширения в один профиль.
            </li>
            <li>
              На один компьютер/сервер можно устанавливать одновременно несколько ботов, но нельзя сворачивать окна профилей браузера. Окна можно перекрывать другими окнами, но в идеале нужно выстроить окна лесенкой, чтобы каждое хоть немного было видно.
            </li>
            <li>
              В профиле браузера/антидетекта помимо страницы БК нужно оставлять еще одну открытую вкладку: либо пустая страница, либо вкладка Расширения. Так как бот иногда может перезапускаться, закрывая при этом страницу БК и если она одна, то, соответственно, закроется и сам профиль.
            </li>
            <li>
              Если вам нужно что-то сделать в БК (поставить ставку руками, пополнить, вывести и тд), то лучше остановить бота просто выключив расширение, чтобы он не мешал. Когда вы включите расширение, бот автоматически запустится.
            </li>
            <li>
              Для корректной работы бота необходимо, чтобы сайты БК были открыты на английской версии сайта, на других языках бот работать не будет. Это касается всех БК, кроме OLIMP, LEON, WINLINE, 1XBET, BETBOOM, LIGASTAVOK, MARATHON.CUPIS, MELBET, PARIBET. Эти БК работают на русском языке.
            </li>
          </ul>
        </div>
        <h2>Где взять сервера, прокси, антидетект?</h2>
        <div class="article">
          <ul>
            <li>
              Мы рекомендуем устанавливать ботов на свой компьютер, чтобы антифрод БК был более лоялен. Но если нет такой возможности, то советуем следующие варианты:<br>
              <a href="https://www.vdsina.com/?partner=jeud2m1x5t" target="_blank">vdsina</a><br>
              <a href="https://macloud.ru/?partner=uxg6l4j1uv" target="_blank">macloud</a><br>
              <a href="https://aeza.net/?ref=455769" target="_blank">aeza</a>
            </li>
            <li>
              Сервера стоит брать из расчета 1 ядро и 2 Гб оперативной памяти на 1 бота. Диск любого размера, не меньше 20 Гб.
            </li>
            <li>
              При покупке прокси, рекомендуем выбирать страну исходя из реальных данных аккаунтов, которые будут ее использовать. Прокси надо брать IPv4.<br>
              https://proxyline.net?ref=36306<br>
              https://proxys.io/?refid=2441<br>
              https://proxy-sale.com/?partner_link=f4oPSMd4mV
            </li>
            <li>
              Антидетект:<br>
              <a href="https://go.gologin.com/betexy-IDNBDGA" target="_blank">GoLogin</a><br>
              <a href="https://browser.vision/r/5eb532b6-007f-4a33-b99c-f19d885896ef" target="_blank">Vision</a><br>
              <a href="https://share.adspower.net/betexy" target="_blank">AdsPower</a>
            </li>
          </ul>
        </div>
        <h2 id="contacts">Остались вопросы?</h2>
        <div class="article">
          Заходите в наш телеграм канал! - <a target="_blank" href="https://t.me/betexychat">BETEXY</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Help",
  metaInfo() {
    return {
      title: this.$ml.get('titles_pages_help')
    }
  }
}
</script>

<style scoped>

</style>